export { default as HowItWorksArrow } from './HowItWorksArrow'
export { default as LogoFooter } from './LogoFooter'
export { default as WheatRight } from './WheatRight'
export { default as MicroMetha } from './MicroMetha'
export { default as Agroforesterie } from './Agroforesterie'
export { default as SolsVivants } from './SolsVivants'
export { default as CurvedStroke } from './CurvedStroke'
export { default as CurvedStrokeVertical } from './CurvedStrokeVertical'
export { default as BienEtreAnimal } from './BienEtreAnimal'
export { default as Quotes } from './Quotes'
