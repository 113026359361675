import { createIcon } from '@chakra-ui/icons'

export default createIcon({
  displayName: 'Quotes',
  viewBox: '0 0 116 86',
  path: (
    <>
      <path
        id="Vector"
        d="M26.84 0C12.04 0 0 12.04 0 26.84C0 41.64 12.04 53.68 26.84 53.68C27.63 53.68 28.28 54.31 28.33 55.09C28.35 55.33 29.13 75.28 10.97 85.88C15.53 84.77 20.9 82.95 26.23 80.01C44.44 69.95 53.67 52.06 53.67 26.86C53.67 12.04 41.63 0 26.84 0Z"
        fill="currentColor"
      />
      <path
        id="Vector_2"
        d="M89.12 0C74.33 0 62.28 12.04 62.28 26.84C62.28 41.64 74.32 53.67 89.12 53.67C89.91 53.67 90.56 54.3 90.61 55.08C90.63 55.32 91.41 75.27 73.25 85.87C77.81 84.76 83.18 82.94 88.51 80C106.73 69.94 115.97 52.05 115.97 26.84C115.96 12.04 103.91 0 89.12 0Z"
        fill="currentColor"
      />
    </>
  ),
})
